





































































































.bgMyLiner {
  background-image: linear-gradient(270deg, #3ccdef 0%, #1c9edb 100%);
}
.bgBtnLiner .q-btn__wrapper {
  background-image: linear-gradient(270deg, #3ccdef 0%, #1c9edb 100%);
}
.likeHeader {
  width: 100%;
  height: 70px;
  position: fixed;
  z-index: -1;
}

.links a {
  color: white;
  padding-left: 15px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
.q-toolbar__title span {
  cursor: pointer;
}
.screen--xs .my-card {
  width: 100%;
  max-width: 500px;
}

.screen-lg .my-card {
  width: 90%;
  max-width: 500px;
}
